<template>
  <div class="content">
    <a-modal v-model="open" title="Add Subject" @ok="handleOk" :closable="false" class="modalClass">
      <a-row>
        <a-input v-model="form.studyId" placeholder="Subject ID" />
      </a-row>
      <a-row style="margin-top: 16px">
        <a-input v-model="form.sensorsId" autofocus placeholder="Selected Sensors" />
      </a-row>

      <template slot="footer">
        <a-button class="cancel">Cancel</a-button>
        <a-button class="save">Save</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      form: {},
    }
  },
  methods: {
    handleOk() {},
    openModal() {
      this.open = true
    },
  },
}
</script>

<style scoped lang="less">
.content {
}

.cancel {
  border: none !important;
  color: #7f62ad;
  font-weight: 500;
  font-size: 16px;
}

.save{
      background-color: #7f62ad;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
}
</style>